import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';

import '../../generic-page.scss';

const CSDatabaseGearCheatSheetPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query GearCheatSheetPageQuery {
      gearInfo: allContentfulEmployee(sort: { fields: shortName, order: ASC }) {
        nodes {
          id
          slug
        }
      }
    }
  `);

  return (
    <DashboardLayout className={'generic-page upcoming-characters'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/database">Database</Link>
        </li>
        <li className="divider">/</li>
        <li>Gear cheat sheet</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_gearcheat.png"
            alt="Gear cheat sheet"
          />
        </div>
        <div className="page-details">
          <h1>Gear cheat sheet</h1>
          <h2>List of characters and what kind of gear they use.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Characters and their gear" />
        <div className="characters-list">
          {data.gearInfo.nodes.map((employee, index) => {
            return (
              <>
                {index > 0 && index % 5 === 0 && (
                  <div
                    className="fuse-ad-placeholder"
                    data-fuse="22844297235"
                  ></div>
                )}
                <Employee
                  key={index}
                  slug={employee.slug}
                  mode="card-large"
                  variant="gear"
                />
              </>
            );
          })}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CSDatabaseGearCheatSheetPage;

export const Head: React.FC = () => (
  <Seo
    title="Gear cheat sheet | Counter Side | Prydwen Institute"
    description="List of characters and what kind of gear they use."
  />
);
